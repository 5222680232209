import React, { useEffect } from "react";
import { useState } from "react";

export default function App() {
  const [list, setList] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [comments, setComments] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);

  useEffect(function () {
    Promise.all([
      fetch("https://jsonplaceholder.typicode.com/posts"),
      fetch("https://jsonplaceholder.typicode.com/users"),
    ]).then(async (res) => {
      // Két adatforrásból (posts és users) egyszerre kér adatokat a Promise.all-al.
      // Adatokat JSON formátumra alakítja.
      const posts = await res[0].json();
      const users = await res[1].json();

      // postUser tartalmazza a posztokat, és mindegyikhez hozzáadja a hozzárendelt felhasználót.
      const postUser = posts.map((post) => {
        const user = users.find((user) => user.id === post.userId) || {
          name: "",
          id: null,
        };
        return {
          ...post,
          user: user,
        };
      });

      // A setList függvény frissíti az állapotot, beállítva az új, felhasználóval kibővített posztokat.
      setList(postUser);
    });
  }, []);

  // Ha egy sorra kattintunk, a postId alapján kikeresi a kiválasztott posztot.
  const handleRowClick = async (postId) => {
    // Kiválasztott post és a user adatainak betöltése
    const post = list.find((item) => item.id === postId);
    const user = post.user;

    // Számolja, hogy a felhasználónak hány kommentje van az összes poszt közül.
    const commentsRes = await fetch(
      `https://jsonplaceholder.typicode.com/posts/${postId}/comments`
    );
    const postComments = await commentsRes.json();

    // Felhasználóhoz tartozó összes poszt megszámolása
    const userPostsCount = list.filter(
      (item) => item.user.id === user.id
    ).length;

    setSelectedPost(post);
    setSelectedUser({ ...user, postsCount: userPostsCount });
    setComments(postComments);
    setSelectedPostId(postId);
  };

  return (
    <div className="conatainer">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>TITLE</th>
              <th>NAME</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item) => (
              <tr
                key={item.id}
                onClick={() => handleRowClick(item.id)}
                className={item.id === selectedPostId ? "selected-row" : ""}
              >
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td>{item.user.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Részletes nézet a kiválasztott posthoz */}

      <div className="postDetails">
        {selectedPost && selectedUser && (
          <div>
            <h2>Post details</h2>
            <p>User ID: {selectedPost.userId}</p>
            <p>ID: {selectedPost.id}</p>
            <p>Title: {selectedPost.title}</p>
            <p>Body: {selectedPost.body}</p>
            <h3>Author information</h3>
            <p>Name: {selectedUser.name}</p>
            <p>Number of posts: {selectedUser.postsCount}</p>
            <p>Username: {selectedUser.username}</p>
            <p>Email: {selectedUser.email}</p>
            <h4>Address</h4>
            <p>street: {selectedUser.address.street}</p>
            <p>suite: {selectedUser.address.suite}</p>
            <p>city: {selectedUser.address.city}</p>
            <p>zipcode: {selectedUser.address.zipcode}</p>
            <h4>GEO</h4>
            <p>lat: {selectedUser.address.geo.lat}</p>
            <p>lng: {selectedUser.address.geo.lng}</p>
            <p>phone: {selectedUser.phone}</p>
            <p>website: {selectedUser.website}</p>
            <h4>Company</h4>
            <p>name: {selectedUser.company.name}</p>
            <p>catchPhrase: {selectedUser.company.catchPhrase}</p>
            <p>bs: {selectedUser.company.bs}</p>
            <h3>Comments</h3>
            {comments.length > 0 ? (
              <ul>
                {comments.map((comment) => (
                  <li key={comment.id}>
                    <p>
                      {comment.name}: {comment.body}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Nincsenek kommentek.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
